.login-wrapper {
  display: flex;
  flex-direction: column;

  span {
    width: 114px;
    display: inline-block;
  }

  input,
  button {
    max-width: 346px;
  }
}
