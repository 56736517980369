.form-wrapper {
  .bold {
    font-weight: bold;
  }

  .select-wrapper {
    display: flex;
    justify-content: center;
  }

  .select-label {
    select {
      margin-right: 10px;
    }
  }

  .checks {
    .status {
      text-align: center;

      img {
        width: 50px;
        height: 50px;
        margin: 0 20px;

        &.checked {
          border: 4px solid black;
        }
      }
    }

    .description {
      textarea {
        box-sizing: border-box;
        border: 1px solid black;
        width: 100%;
      }
    }
  }

  .facilities {
    .facility {
      display: flex;
      justify-content: space-around;
    }
  }

  .images-wrapper {
    display: flex;
    flex-wrap: wrap;

    img {
      max-width: 230px;
      max-height: 160px;
      margin: 10px;
    }
  }

  input[type="file"] {
    display: none;
  }
}
