.admin-reports-wrapper {
  .filter-section {
    b {
      font-weight: bold;
    }
    span {
      width: 110px;
      display: inline-block;
    }
    span.second {
      display: inline;
      margin: 0 5px;
    }
  }

  img {
    width: 20px;
    height: 20px;
  }

  .garden-images-wrapper {
    img {
      max-width: 230px;
      max-height: 160px;
      margin: 10px;
      width: auto;
      height: auto;
      cursor: pointer;
    }
  }
}
